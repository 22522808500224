<template>
  <div class="home">
    <h1>乐票闪贴</h1>
    <p>高效便捷的票据融资业务</p>
    <img
      src="./image/ad.png"
      alt="乐票闪贴"
      width="560"
      style="margin-left: 264px"
    />
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.home {
  min-width: 100%;
  min-height: 100%;
  background: url('./image/bg.png') no-repeat;
  background-size: cover;

  h1 {
    font-size: 60px;
    font-weight: 600;
    color: #fff;
    line-height: 100px;
    padding: 118px 0 0 280px;
  }

  p {
    font-size: 30px;
    color: #fff;
    line-height: 50px;
    padding-left: 280px;
    margin-bottom: 50px;
  }
}
</style>
